import React from 'react';

import {
  createConnectedRouter,
  createRender,
} from 'found';

const Router = createConnectedRouter({
  render: createRender({
    renderError: ({ error }) => ( // eslint-disable-line react/prop-types
      <div>
        {error.status === 404 ? 'Not found' : 'Error'}
      </div>
    ),
  }),
});

export default Router;
