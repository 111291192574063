import S from 'common/service/sanctuary';
import { connect } from 'react-redux';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bool, arrayOf, func } from 'prop-types';
import Table, { TableHead, TableCell, TableRow, SELECTION_MULTIPLE } from 'common/mdc/table';

import {
  selectors as onDemandReadingSelectors,
} from '@sma/store/device/onDemandReading';

import registerShape from './shape';
import { RegisterObis } from './name';

const messages = defineMessages({
  displaySelected: {
    id: 'smas.registers.on-demand-reading.filter.selected',
    defaultMessage: 'Filter selected',
  },
  obisCode: {
    id: 'smas.registers.on-demand-reading.robisCodegister',
    defaultMessage: 'Obis code/IC',
  },
  name: {
    id: 'smas.registers.on-demand-reading.name',
    defaultMessage: 'Name',
  },
  sampleData: {
    id: 'smas.registers.on-demand-reading.sampleData',
    defaultMessage: 'Sample data',
  },
});

export const RegisterSelectionTable = ({
  registers,
  sort,
  selected,
  onSelect,
  checked,
  onSelectionChange,
  getSample,
  dispatch,
  ...props
}) => (
  <Table selectable selectionType={SELECTION_MULTIPLE} className="onDemandReadingTable" {...props}>
    <TableHead onSelectionChange={onSelectionChange} checked={checked}>
      <TableCell><FormattedMessage {...messages.name} /></TableCell>
      <TableCell><FormattedMessage {...messages.obisCode} /></TableCell>
      <TableCell alignRight><FormattedMessage {...messages.sampleData} /></TableCell>
    </TableHead>
    {registers.map((register, idx) => (
      <TableRow
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        checked={!!selected(register)}
        onSelect={() => onSelect(register)}
        onClick={() => onSelect(register)}
      >
        <TableCell>
          {register.name}
        </TableCell>
        <TableCell>
          <RegisterObis register={register} />
        </TableCell>
        <TableCell>
          {S.maybeToNullable(getSample(register))}
        </TableCell>
      </TableRow>
    ))}
  </Table>
);

RegisterSelectionTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  sort: func,
  selected: func.isRequired,
  onSelect: func.isRequired,
  onSelectionChange: func.isRequired,
  checked: bool.isRequired,
  getSample: func.isRequired,
};

RegisterSelectionTable.defaultProps = {
  sort: () => {},
};

export default connect(
  state => ({
    getSample: onDemandReadingSelectors.getSample(state),
  }),
)(RegisterSelectionTable);
