import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';

import {
  LOAD, load,
  loadSuccess,
  loadError,
  download,
  downloadSuccess,
  downloadError,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  SEARCH,
  SET_SELECTED,
  ADVANCED_SEARCH,
  DOWNLOAD,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} from './actions';

import {
  getStoreParams,
} from './selectors';

import {
  actions,
} from '../device';

import purgeEpic from './purgeEpic';

const loadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getAll(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const downloadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(DOWNLOAD),
  switchMap(action$ => deviceService
    .downloadAll(getStoreParams(state.value))
    .pipe(
      map(downloadSuccess),
      catchError(createErrorStream(action$, downloadError)),
    )
  ),
);

const update = action => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE, ADVANCED_SEARCH),
  map(load),
);

const search = action => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(load),
);

const loadSelected = action => action.pipe(
  ofType(SET_SELECTED),
  map(({ selected }) => actions.load(selected.deviceId)),
);

export default combineEpics(
  loadEpic,
  downloadEpic,
  purgeEpic,
  update,
  search,
  loadSelected,
);
