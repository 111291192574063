import React from 'react';
import {
  makeRouteConfig,
  Route,
  Redirect,
  RedirectException,
} from 'found';

import App from '@sma/app';

import {
  Dashboard as MainDashboard,
  Devices,
  Device,
  Groups,
  GroupCreate,
  MeteringProfiles,
  GroupDetails,
  GroupEdit,
} from '@sma/page';

import {
  Dashboard as DeviceDashboard,
  MeteringProfiles as DeviceMeteringProfiles,
  Samples as DeviceSamples,
  Alarms as DeviceAlarms,
  Events as DeviceEvents,
} from '@sma/page/device';

import {
  actions as widgetDeviceStatusActions,
} from '@sma/store/widgets/status';

import {
  actions as widgetDeviceNetworkStatusActions,
} from '@sma/store/widgets/network';

import {
  actions as widgetDeviceConsumerStatusActions,
} from '@sma/store/widgets/consumer';

import {
  actions as widgetDeviceSupplyStatusActions,
} from '@sma/store/widgets/supply';

import {
  actions as widgetDeviceFirmwareActions,
} from '@sma/store/widgets/firmware';

import {
  actions as devicesActions,
} from '@sma/store/device/devices';

import {
  actions as searchDevicesActions,
} from '@sma/store/device/search';

import {
  actions as deviceAlarmsActions,
} from '@sma/store/device/alarms';

import {
  actions as deviceEventsActions,
} from '@sma/store/device/events';

import {
  actions as groupsActions,
} from '@sma/store/group/groups';

import {
  actions as groupActions,
} from '@sma/store/group/group';

import {
  actions as meteringProfileActions,
} from '@sma/store/meteringProfile/meteringProfiles';

import {
		 actions as widgetServerVersionActions,
		} from 'common/store/server/version';

import { actions as deviceMeteringProfileActions } from '@sma/store/device/meteringProfile';
import { clearAll as clearSamples } from '@sma/store/device/meteringProfileSamples/actions';
import { load as loadDevice } from '@sma/store/device/device/actions';
import { actions as groupDevicesActions } from '@sma/store/group/devices';
import WithDialog from 'common/mdc/dialog/withDialog';
// import { selectors } from '@sma/store/device/onDemandReading';
import OnDemandReading from '@sma/components/device/onDemandReading';

export default (() => makeRouteConfig(
  <Route
    path="/"
    Component={
      WithDialog(OnDemandReading, 'onDemandReading')(App)
    }
  >
    <Route path="dashboard"
      getData={({ context: { store } }) => {
        store.dispatch(widgetDeviceStatusActions.load());
        store.dispatch(widgetDeviceNetworkStatusActions.load());
        store.dispatch(widgetDeviceConsumerStatusActions.load());
        store.dispatch(widgetDeviceSupplyStatusActions.load());
        store.dispatch(widgetServerVersionActions.load());
        store.dispatch(widgetDeviceFirmwareActions.load());
      }}
      Component={MainDashboard}
    />
    <Route path="devices">
      <Route
        exact
        Component={Devices}
        getData={({ location: { params }, context: { store } }) => {
            if (params && params.initialCriteria) {
              store.dispatch(searchDevicesActions.loadCriteria(params.initialCriteria));
            }
          store.dispatch(devicesActions.load({ limit: 10, offset: 0 }));
        }}
      />
      <Route
        path=":deviceId"
        getData={({ params: { deviceId }, context: { store } }) => {
          store.dispatch(loadDevice(deviceId));
        }}
        Component={Device}
      >
        <Route
          exact
          Component={DeviceDashboard}
        />
        <Route
          path="metering-profiles"
          Component={DeviceMeteringProfiles}
          getData={({ params: { deviceId }, context: { store } }) => {
            store.dispatch(deviceMeteringProfileActions.load(deviceId));
          }}
        />
        <Route
          path="samples"
          Component={DeviceSamples}
          getData={({ params: { deviceId }, context: { store } }) => {
            store.dispatch(clearSamples());
            store.dispatch(deviceMeteringProfileActions.load(deviceId));
          }}
        />
        <Route
          path="alarms"
          Component={DeviceAlarms}
          getData={({ params: { deviceId }, context: { store } }) => {
            store.dispatch(deviceAlarmsActions.load({
                deviceId,
                limit: 10,
                offset: 0,
                to: new Date(),
                from: new Date(Date.now() - 86400000),
            }));
          }}
        />
        <Route
          path="events"
          Component={DeviceEvents}
          getData={({ params: { deviceId }, context: { store } }) => {
            store.dispatch(deviceEventsActions.load({
              deviceId,
              limit: 10,
              offset: 0,
              to: new Date(),
              from: new Date(Date.now() - 86400000),
            }));
          }}
        />
      </Route>
    </Route>
    <Route path="groups">
      <Route
        exact
        Component={Groups}
        getData={({ context: { store } }) => {
          store.dispatch(groupsActions.load());
        }}
      />
      <Route
        exact
        path="create"
        Component={GroupCreate}
      />
      <Route
        path=":groupId"
        getData={({ params: { groupId }, context: { store } }) => {
          store.dispatch(groupDevicesActions.clear());
          store.dispatch(groupActions.load(groupId));
        }}
      >
        <Route
          exact
          Component={GroupDetails}
        />
        <Route
          path="edit"
          Component={GroupEdit}
          getData={({ params: { groupId }, context: { store } }) => {
            store.dispatch(groupDevicesActions.load(groupId, { limit: null, offset: null }));
            store.dispatch(devicesActions.load({ limit: 10, offset: 0 }));
          }}
        />
      </Route>
    </Route>
    <Route path="metering-profiles">
      <Route
        exact
        Component={MeteringProfiles}
        getData={({ context: { store } }) => {
          store.dispatch(meteringProfileActions.load());
        }}
      />
    </Route>
    <Redirect from="/" to="/dashboard" />
  </Route>,
));
