import S from 'common/service/sanctuary';
import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { prop } from 'ramda';

import { createErrorStream } from 'common/store/error';
import {
  LOAD_ALL, loadAll, loadAllSuccess, loadAllError,
  UPDATE_ALL, PAGINATION_CHANGE,
  DOWNLOAD_ALL, downloadAll, downloadAllSuccess, downloadAllError,
  TOGGLE_SORT,
  SELECT_PROFILE,
  setRegisters,
} from './actions';

import {
  getDeviceId,
} from '../meteringProfile/selectors';

import {
  getStoreParams,
  getMeteringProfileId,
  getMeteringProfile,
} from './selectors';

const loadAllEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD_ALL),
  switchMap(action$ => deviceService
    .getMeteringProfileSamples(
      action$.deviceId,
      action$.meteringProfileId,
      getStoreParams(state.value),
    )
    .pipe(
      map(loadAllSuccess),
      catchError(createErrorStream(action$, loadAllError)),
    )),
);

const downloadAllEpic = (action, state, { deviceService }) => action.pipe(
  ofType(DOWNLOAD_ALL),
  switchMap(action$ => deviceService
    .downloadMeteringProfileSamples(
      S.maybeToNullable(getDeviceId(state.value)),
      S.maybeToNullable(getMeteringProfile(state.value)),
      getStoreParams(state.value),
    )
    .pipe(
      map(downloadAllSuccess),
      catchError(createErrorStream(action$, downloadAllError)),
    )),
);

const updateAllEpic = (action, state) => action.pipe(
  ofType(UPDATE_ALL, TOGGLE_SORT, PAGINATION_CHANGE, SELECT_PROFILE),
  map(() => (
    loadAll(
      S.maybeToNullable(getDeviceId(state.value)),
      S.maybeToNullable(getMeteringProfileId(state.value)),
    )
  )),
);

const selectProfileEpic = (action, state) => action.pipe(
  ofType(SELECT_PROFILE),
  map(() => setRegisters(
    S.maybe([], prop('registers'), getMeteringProfile(state.value)),
  )),
);

export default combineEpics(
  loadAllEpic,
  downloadAllEpic,
  updateAllEpic,
  selectProfileEpic,
);
