import S from 'common/service/sanctuary';
import { compose, prop } from 'ramda';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { func, bool, string, shape, oneOf, instanceOf } from 'prop-types';
import { injectIntl, FormattedMessage, intlShape, defineMessages } from 'react-intl';
import Button from 'common/mdc/button';
import RadioField from 'common/mdc/form/radio';
import DatetimeRange from 'common/mdc/form/datetimeRange';
import Dialog from 'common/mdc/dialog';
import withMultiSelection from 'common/mdc/table/multiSelection';
import Select, { SelectItem } from 'common/mdc/select';
import Toolbar, { ToolbarRow, ToolbarSection } from 'common/mdc/toolbar';

import {
  actions as meteringProfileActions,
  selectors as meteringProfileSelectors,
  features,
} from '@sma/store/device/meteringProfile';

import {
  actions as onDemandReadingActions,
  selectors as onDemandReadingSelectors,
} from '@sma/store/device/onDemandReading';

import {
  actions as onDemandHistoryActions,
  selectors as onDemandHistorySelectors,
} from '@sma/store/device/onDemandHistory';

import HistorySamples from './historyTable';
import ImmediateSamples from './immediateSamples';

const messages = defineMessages({
  onDemandReading: {
    id: 'smas.devices.actions.on-demand-reading.on-demand-reading',
    defaultMessage: 'On demand reading from {deviceId}',
  },
  register: {
    id: 'smas.devices.actions.on-demand-reading.register',
    defaultMessage: 'Register',
  },
  sampleData: {
    id: 'smas.devices.actions.on-demand-reading.sampleData',
    defaultMessage: 'Sample data',
  },
  collectData: {
    id: 'smas.devices.actions.on-demand-reading.collectData',
    defaultMessage: 'Collect data',
  },
  cancel: {
    id: 'smas.devices.actions.on-demand-reading.cancel',
    defaultMessage: 'Cancel',
  },
  immediateSamples: {
    id: 'smas.devices.actions.on-demand-reading.immediateSamples',
    defaultMessage: 'Immediate samples',
  },
  sampleHistory: {
    id: 'smas.devices.actions.on-demand-reading.sampleHistory',
    defaultMessage: 'Sample history',
  },
  from: {
    id: 'smas.devices.actions.on-demand-reading.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'smas.devices.actions.on-demand-reading.to',
    defaultMessage: 'To',
  },
});

const DAILY_MS = 86400000;

class OnDemandReading extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.initialChecked,
      from: props.initialFrom,
      to: props.initialTo,
    };

    this.setChecked = this.setChecked.bind(this);
    this.loadSamples = this.loadSamples.bind(this);
    this.loadSamplesHistory = this.loadSamplesHistory.bind(this);
  }

  componentDidMount() {
    const {
      deviceId,
      loadMeteringProfiles,
      deviceIdMaybe,
      deviceMeteringProfilesContext,
      clearHistory,
    } = this.props;

    if (deviceMeteringProfilesContext.isNothing || S.maybeToNullable(deviceIdMaybe) !== deviceId) {
      loadMeteringProfiles(deviceId);
    }

    clearHistory();
  }

  setChecked(checked) {
    return this.setState({ checked });
  }

  loadSamplesHistory() {
    const { onDemandReading } = this.context;
    const { deviceId, loadSamplesHistory, meteringProfileMaybe } = this.props;
    const { from, to } = this.state;

    loadSamplesHistory({
      deviceId,
      from,
      to,
      onDemandReading,
      meteringProfile: S.maybeToNullable(meteringProfileMaybe),
    });
  }

  loadSamples() {
    const { multiSelection, meteringProfileMaybe } = this.props;

    this.props.loadSamples({
      deviceId: this.props.deviceId,
      registers: multiSelection.getSelection()
        .map(register => ({
          ...register,
          profileObisCode: S.maybeToNullable(S.map(
            prop('profileObisCode'),
            meteringProfileMaybe,
          )),
        })),
    });
  }

  render() {
    const {
      intl,
      profilesLoading,
      historyLoading,
      getProfilesMaybe,
      meteringProfileMaybe,
      samplesLoading,
      multiSelection,
      onSelectProfile,
      clearHistory,
      onClose,
      onClick,
      onDemandSamplingClose,
      deviceId,
    } = this.props;

    const { checked, from, to } = this.state;

    const onSelectProfile$ = compose(
      clearHistory,
      multiSelection.clear,
      onSelectProfile,
    );

    const onClose$ = compose(
      onDemandSamplingClose,
      onClose,
    );

    const onDemandMeteringProfilesMaybe = getProfilesMaybe(checked);

    return (
      <Dialog
        title={intl.formatMessage(messages.onDemandReading, { deviceId })}
        onClose={onClose$}
        loading={profilesLoading || samplesLoading || historyLoading}
        onClick={onClick}
        buttons={
          <Fragment>
            <Button
              onClick={onClose$}
              className="mdc-dialog__footer__button"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              raised
              onClick={checked === features.ON_DEMAND ? this.loadSamples : this.loadSamplesHistory}
              disabled={!multiSelection.hasSelection() && checked === features.ON_DEMAND}
              className="mdc-dialog__footer__button"
            >
              <FormattedMessage {...messages.collectData} />
            </Button>
          </Fragment>
        }
      >
        <Toolbar>
          <ToolbarRow>
            <ToolbarSection className="radio-buttons" align={ToolbarSection.ALIGN_START}>
              <RadioField
                disabled={S.maybe(
                  false,
                  S.complement(meteringProfileSelectors.hasFeature(
                    features.ON_DEMAND,
                  )),
                  meteringProfileMaybe,
                )}
                id={features.ON_DEMAND}
                checked={checked === features.ON_DEMAND}
                onChange={() => this.setChecked(features.ON_DEMAND)}
                label={<FormattedMessage {...messages.immediateSamples} />}
              />
              <RadioField
                disabled={S.maybe(
                  false,
                  S.complement(meteringProfileSelectors.hasFeature(
                    features.ON_DEMAND_PROFILE,
                  )),
                  meteringProfileMaybe,
                )}
                id={features.ON_DEMAND_PROFILE}
                checked={checked === features.ON_DEMAND_PROFILE}
                onChange={() => this.setChecked(features.ON_DEMAND_PROFILE)}
                label={<FormattedMessage {...messages.sampleHistory} />}
              />
            </ToolbarSection>
            <ToolbarSection shrink align={ToolbarSection.ALIGN_START}>
              {checked === features.ON_DEMAND_PROFILE && (
                <Fragment>
                  <DatetimeRange
                    from={from}
                    to={to}
                    onChange={period => this.setState(period)}
                  />
                </Fragment>
              )}
            </ToolbarSection>
            <ToolbarSection align={ToolbarSection.ALIGN_END}>
              {S.maybeToNullable(S.map(
                meteringProfiles => S.maybeToNullable(S.map(
                  meteringProfile => (
                    <Select
                      // this forces remount, as we change select items
                      key={checked}
                      onChange={onSelectProfile$}
                      value={meteringProfile.id}
                      id="metering-profiles-select"
                    >
                      {meteringProfiles.map(({ id, name }) => (
                        <SelectItem value={id} key={id}>
                          {name}
                        </SelectItem>
                      ))}
                    </Select>
                  ),
                  meteringProfileMaybe,
                )),
                onDemandMeteringProfilesMaybe,
              ))}
            </ToolbarSection>
          </ToolbarRow>
        </Toolbar>
        {checked === features.ON_DEMAND
          ? (
            <ImmediateSamples
              multiSelection={multiSelection}
              meteringProfileMaybe={meteringProfileMaybe}
            />
          ) : (
            <HistorySamples
              registers={S.maybeToNullable(S.map(
                meteringProfile => meteringProfile.registers,
                meteringProfileMaybe,
              ))}
            />
          )}
      </Dialog>
    );
  }
}

OnDemandReading.propTypes = ({
  intl: intlShape.isRequired,
  loadMeteringProfiles: func.isRequired,
  loadSamples: func.isRequired,
  onClose: func.isRequired,
  onSelectProfile: func.isRequired,
  profilesLoading: bool.isRequired,
  samplesLoading: bool.isRequired,
  deviceId: string.isRequired,
  onDemandSamplingClose: func.isRequired,
  historyLoading: bool.isRequired,
  getProfilesMaybe: func.isRequired,
  clearHistory: func.isRequired,
  onClick: func.isRequired,
  deviceIdMaybe: shape().isRequired,
  loadSamplesHistory: func.isRequired,
  meteringProfileMaybe: shape().isRequired,
  multiSelection: shape().isRequired,
  initialChecked: oneOf([features.ON_DEMAND, features.ON_DEMAND_PROFILE]),
  initialFrom: instanceOf(Date),
  initialTo: instanceOf(Date),
});

OnDemandReading.defaultProps = ({
  initialChecked: features.ON_DEMAND,
  initialFrom: new Date(Date.now() - DAILY_MS),
  initialTo: new Date(),
});

OnDemandReading.contextTypes = ({
  onDemandReading: func.isRequired,
});

export default compose(
  connect(
    (state, { meteringProfile }) => ({
      profilesLoading: meteringProfileSelectors.isLoading(state),
      samplesLoading: onDemandReadingSelectors.isLoading(state),
      historyLoading: onDemandHistorySelectors.isLoading(state),
      getProfilesMaybe: onDemandReadingSelectors
        .getMeterginProfilesByFeature(state),
      deviceMeteringProfilesContext: meteringProfileSelectors.getDeviceProfilesContext(state),
      deviceIdMaybe: meteringProfileSelectors.getDeviceId(state),
      meteringProfileMaybe: S.compose(
        S.alt(onDemandReadingSelectors.getSelectedMeteringProfile(state)),
        S.alt(S.toMaybe(meteringProfile)),
      )(
        S.chain(
          S.head,
          onDemandReadingSelectors.getMeterginProfilesByFeature(state)(features.ON_DEMAND),
        ),
      ),
    }),
    ({
      loadMeteringProfiles: meteringProfileActions.load,
      loadSamples: onDemandReadingActions.load,
      loadSamplesHistory: onDemandHistoryActions.load,
      clearHistory: onDemandHistoryActions.clear,
      onSelectProfile: onDemandReadingActions.selectMeteringProfile,
      onDemandSamplingClose: onDemandReadingActions.onDemandSamplingClose,
    }),
  ),
  injectIntl,
  withMultiSelection({
    mapItemToSelection: ({
      attributeId,
      obisCode,
    }) => ({ attributeId, obisCode }),
  }),
)(OnDemandReading);
