import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error/index';

import {
  LOAD, load, loadSuccess, loadError,
  download,
  downloadSuccess,
  downloadError,
  TOGGLE_SORT, SEARCH, PAGINATION_CHANGE,
  DOWNLOAD,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} from './actions';

import {
  getStoreParams,
} from './selectors';

const loadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getAlarms(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const downloadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(DOWNLOAD),
  switchMap(action$ => deviceService
    .downloadAlarms(getStoreParams(state.value))
    .pipe(
      map(downloadSuccess),
      catchError(createErrorStream(action$, downloadError)),
    )
  ),
);

const updateEpic = action => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE),
  map(load),
);

const searchEpic = action => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(load),
);

export default combineEpics(
  loadEpic,
  downloadEpic,
  updateEpic,
  searchEpic,
);
