import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import {
  resource,
} from 'common/store/selectors';

export const getScope = ({ widgets: { statusData } }) => statusData;

const chooseSafeNumber = (l, r) => (Number(r) || l);
const defaultStatusWidgetData = {
    categoryOne: {
      online: 0,
      offline: 0,
      unknown: 0,
    },
    categoryTwo: {
      online: 0,
      offline: 0,
      unknown: 0,
    },
    categoryThree: {
      online: 0,
      offline: 0,
      unknown: 0,
    },
    categoryFour: {
      online: 0,
      offline: 0,
      unknown: 0,
    },
    categoryFive: {
      online: 0,
      offline: 0,
      unknown: 0,
    }
};

export const {
  isLoading,
  getResourceContext,
  getResourceMaybe,
} = resource.factory(getScope);

export const getError = createSelector(
  getScope,
  ({ error }) => error,
);

export const getStatusWidgetMaybe = createSelector(
  getResourceMaybe,
  resourceMaybe => S.map(
    R.mergeDeepWith(chooseSafeNumber, defaultStatusWidgetData),
    S.chain(S.toMaybe, resourceMaybe),
  ),
);
