import React from 'react';
import { defineMessages } from 'react-intl';
import * as R from 'ramda';
import { Actions as FarceActions } from 'farce';
import { addNotification, replaceNotification } from 'common/mdc/notifications/actions';
import {
  TYPE_SUCCESS, TYPE_ERROR, TYPE_PENDING,
} from 'common/mdc/notifications/notification';
import { PublicError } from 'common/service/error';
import { createErrorStream } from 'common/store/error';
import DeviceLink from '@sma/components/device/link';
import { ofType } from 'redux-observable';
import { map, switchMap, startWith, catchError } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';

import { actions } from './index';

const CONNECTED = 'CONNECTED';

const messages = defineMessages({
  title: {
    id: 'device.actions.ping',
    defaultMessage: 'Ping',
  },
  pending: {
    id: 'device.actions.ping.pending',
    defaultMessage: 'Awaiting response with state from meter {deviceId}',
  },
  successConnected: {
    id: 'device.actions.ping.success.connected',
    defaultMessage: 'Meter {deviceId} is connected',
  },
  successNotConnected: {
    id: 'device.actions.ping.success.notConnected',
    defaultMessage: 'Meter {deviceId} is not connected',
  },
  failure: {
    id: 'device.actions.ping.failed',
    defaultMessage: 'Meter ping {deviceId} was failed',
  },
  retry: {
    id: 'device.actions.ping.retry',
    defaultMessage: 'Retry ping',
  },
  viewPingDetails: {
    id: 'device.actions.ping.details',
    defaultMessage: 'View device details',
  },
});

const clickEventStream = new Subject();

const pingEpiq = (action, state, { deviceService, intl }) => merge(
  clickEventStream,
  action.pipe(
    ofType(actions.LOAD),
    switchMap((action$) => {
      const { deviceId } = action$;
      const pendingNotification = addNotification({
        autoHide: false,
        type: TYPE_PENDING,
        title: intl.formatMessage(messages.title),
        text: <DeviceLink deviceId={deviceId} message={messages.pending} />,
      });

      const retryPing = ({
        text: intl.formatMessage(messages.retry),
        icon: 'autorenew',
        onClick: () => clickEventStream.next(actions.load(deviceId)),
      });

      const viewDetails = ({
        text: intl.formatMessage(messages.viewPingDetails),
        icon: 'open_in_new',
        onClick: () => clickEventStream.next(FarceActions.push(`/devices/${deviceId}`)),
      });

      const failureMessage = <DeviceLink deviceId={deviceId} message={messages.failure} />;

      return deviceService
        .getPing(deviceId)
        .pipe(
          map(({ state: state$ }) => replaceNotification(pendingNotification.notification, {
            type: TYPE_SUCCESS,
            autoHide: true,
            text: (
              <DeviceLink
                deviceId={deviceId}
                message={
                  state$ === CONNECTED
                    ? messages.successConnected
                    : messages.successNotConnected
                }
              />
            ),
            actions: [viewDetails],
          })),
          startWith(pendingNotification),
          catchError(createErrorStream(action$,
            err => replaceNotification(pendingNotification.notification, {
              type: TYPE_ERROR,
              autoHide: true,
              text: R.is(PublicError, err) && err.message
                ? err.message
                : failureMessage,
              actions: [retryPing],
            }))),
        );
    }),
  ),
);

export default pingEpiq;
