import React from 'react';
import { is } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap, switchAll, take, takeLast, takeUntil, catchError, startWith, share } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import { defineMessages } from 'react-intl';
import { Subject, merge, of } from 'rxjs';
import { PublicError } from 'common/service/error';

import {
  actions as onDemandReadingActions,
} from '@sma/store/device/onDemandReading';

import {
  features,
} from '@sma/store/device/meteringProfile';

import {
  actions as notificationActions,
  types as notificationType,
} from 'common/mdc/notifications';

import DeviceLink from '@sma/components/device/link';

import { errorNotification } from '@sma/store/device/onDemandReading/epic';

import {
  LOAD,
  loadSuccess,
  loadError,
} from './actions';

import {
  getStoreParams,
} from './selectors';

const messages = defineMessages({
  title: {
    id: 'device.onDemandHistory.title',
    defaultMessage: 'On demand history',
  },
  pending: {
    id: 'device.onDemandHistory.pending',
    defaultMessage: 'Awaiting response from meter {deviceId}',
  },
  ready: {
    id: 'device.onDemandHistory.ready',
    defaultMessage: 'Request is completed meter {deviceId}',
  },
  failure: {
    id: 'device.onDemandHistory.failed',
    defaultMessage: 'Request for meter {deviceId} was failed',
  },
  viewResults: {
    id: 'device.onDemandHistory.details',
    defaultMessage: 'View results',
  },
});

const clickEventStream = new Subject();

const loadSamples = (action, state, { intl, deviceService }) => merge(
  clickEventStream,
  action.pipe(
    ofType(LOAD),
    mergeMap((action$) => {
      const { deviceId, meteringProfile, from, to, onDemandReading } = action$;
      const request = deviceService
        .getOnDemandSamplesHistory({
          ...getStoreParams(state.value),
          deviceId,
          formattedProfileObisCode: meteringProfile.profileObisCode,
          sampleStartTime: from.toISOString(),
          sampleStopTime: to.toISOString(),
        })
        .pipe(share());

      const pendingNotification = notificationActions.addNotification({
        autoHide: false,
        type: notificationType.TYPE_PENDING,
        title: intl.formatMessage(messages.title),
        text: <DeviceLink deviceId={deviceId} message={messages.pending} />,
      });

      const viewResult = ({
        text: intl.formatMessage(messages.viewResults),
        icon: 'open_in_new',
        onClick: () => onDemandReading({
          deviceId,
          meteringProfile,
          initialChecked: features.ON_DEMAND_PROFILE,
          initialFrom: from,
          initialTo: to,
        }),
      });

      const failureMessage = (
        <DeviceLink
          deviceId={deviceId}
          message={messages.failure}
        />
      );

      return action.pipe(
        ofType(onDemandReadingActions.ON_DEMAND_SAMPLING_CLOSE),
        takeUntil(request.pipe(takeLast(1))),
        take(1),
        map(() => request.pipe(
          mergeMap(result => of(
            loadSuccess(result),
            notificationActions.replaceNotification(pendingNotification.notification, {
              type: notificationType.TYPE_SUCCESS,
              autoHide: false,
              text: <DeviceLink deviceId={deviceId} message={messages.ready} />,
              actions: [viewResult],
            }),
          )),
          startWith(pendingNotification),
          catchError(createErrorStream(
            action$,
            loadError,
            err => notificationActions.replaceNotification(pendingNotification.notification, {
              type: notificationType.TYPE_ERROR,
              autoHide: true,
              text: is(PublicError, err) && err.message
                ? err.message
                : failureMessage,
            }),
          )),
        )),
        startWith(request.pipe(map(loadSuccess))),
        switchAll(),
        catchError(createErrorStream(
          action$,
          loadError,
          errorNotification({
            intl,
            subject: clickEventStream,
            action: action$,
          }),
        )),
      );
    }),
  ),
);


export default combineEpics(
  loadSamples,
);
