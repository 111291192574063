import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import {
  resource,
} from 'common/store/selectors';

export const getScope = ({ widgets: { networkData } }) => networkData;

const chooseSafeNumber = (l, r) => (Number(r) || l);
const defaultNetworkWidgetData = {
  categoryOne: {
    loggedIn: 0,
    loggedOff: 0,
    unknown: 0,
  },
  categoryTwo: {
    loggedIn: 0,
    loggedOff: 0,
    unknown: 0,
  },
  categoryThree: {
    loggedIn: 0,
    loggedOff: 0,
    unknown: 0,
  },
  categoryFour: {
    loggedIn: 0,
    loggedOff: 0,
    unknown: 0,
  },
  categoryFive: {
    loggedIn: 0,
    loggedOff: 0,
    unknown: 0,
  }
};

export const {
  isLoading,
  getResourceContext,
  getResourceMaybe,
} = resource.factory(getScope);

export const getError = createSelector(
  getScope,
  ({ error }) => error,
);

export const getNetworkWidgetMaybe = createSelector(
  getResourceMaybe,
  resourceMaybe => S.map(
    R.mergeDeepWith(chooseSafeNumber, defaultNetworkWidgetData),
    S.chain(S.toMaybe, resourceMaybe),
  ),
);
