import {
  collection,
  selection,
} from 'common/store/actions';

export const NAMESPACE = 'DEVICE/DEVICES';
export const PURGE = `${NAMESPACE}/PURGE`;

const collectionActions = collection.factory(NAMESPACE);
const selectionActions = selection.factory(NAMESPACE);

export const {
  LOAD,
  UPDATE,
  LOAD_SUCCESS,
  LOAD_ERROR,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  SEARCH,
  ADVANCED_SEARCH,
  DOWNLOAD,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} = collectionActions.TYPES;

export const {
  SET_SELECTED,
  CLEAR_SELECTED,
} = selectionActions.TYPES;

export const {
  setSelected,
  clearSelected,
} = selectionActions;

export const {
  load,
  loadSuccess,
  loadError,
  toggleSort,
  update,
  search,
  paginationChange,
  advancedSearch,
  download,
  downloadSuccess,
  downloadError,
} = collectionActions;

export const purge = deviceId => ({
  type: PURGE,
  deviceId,
});
