import React from 'react';
import { defineMessages, FormattedMessage, intlShape, injectIntl } from 'react-intl';
import Grid, { GridRow, GridCell } from 'common/mdc/grid';
import Main from 'common/mdc/layout';
import WidgetStatus from './widgets/status/status';
import WidgetNetwork from './widgets/network/network';
import WidgetConsumer from './widgets/consumer/consumer';
import WidgetSupply from './widgets/supply/supply';
import WidgetServer from './widgets/servers/widgetServer';
import WidgetFirmware from './widgets/firmware/firmware';
import ScrollContainer from 'common/mdc/scrollbar/container';

const messages = defineMessages({
  title: {
    id: 'title.dashboard',
    defaultMessage: 'Dashboard',
  },
});

const Dashboard = () => (
  <Main
    title={<FormattedMessage {...messages.title} />}
  >
    <Grid container className="main-dashboard">
      <ScrollContainer settings={{ suppressScrollX: true }}>
        <div>
          <Grid item xs={12} sm={6}>
            <GridRow>
              <GridCell>
                <WidgetStatus />
              </GridCell>
              <GridCell>
                <WidgetNetwork />
              </GridCell>
              <WidgetConsumer />
              <GridCell>
                <WidgetFirmware />
              </GridCell>
              <GridCell>
                 <WidgetServer />
              </GridCell>
              {/*<GridCell>
                <WidgetSupply />
              </GridCell>*/}
            </GridRow>
          </Grid>
        </div>
      </ScrollContainer>
    </Grid>
  </Main>
);

export default Dashboard;
