import S from 'common/service/sanctuary';
import { compose } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { shape, func, bool } from 'prop-types';
import { defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Form, { DisplayField } from 'common/mdc/form';
import { StepperContent } from 'common/mdc/stepper';
import TextField from 'common/mdc/textfield';
import Button from 'common/mdc/button';
import { toDSL } from 'common/store/search/advanced/selectors';

import { selectors, actions } from '@sma/store/group/create';
import GroupType, { TYPE_DYNAMIC } from '@sma/components/group/groupType';
import { DisplaySearch } from '@sma/components/device/search';

import {
  STEP_ID as STEP1,
} from './step1';

export const STEP_ID = Symbol('preview-and-run');

const messages = defineMessages({
  title: {
    id: 'group-create.step3.title',
    defaultMessage: 'Preview and save',
  },
  complete: {
    id: 'groupCreate.actions.complete',
    defaultMessage: 'Save group',
  },
  groupName: {
    id: 'jobs.group-create.label.group-name',
    defaultMessage: 'Group name',
  },
  groupType: {
    id: 'jobs.group-create.label.group-type',
    defaultMessage: 'Group type',
  },
  criteria: {
    id: 'jobs.group-create.label.criteria',
    defaultMessage: 'Criteria',
  },
  devicesSelected: {
    id: 'jobs.group-create.label.devicesSelected',
    defaultMessage: 'Selected meters',
  },
});

export const title = <FormattedMessage {...messages.title} />;

class Step3 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.complete = this.complete.bind(this);
  }

  complete() {
    this.props.stepper.removeHook();

    S.maybeToNullable(S.map(
      ({ type, selection, criteria }) => this.props.create({
        name: this.nameInput.value,
        type,
        devices: selection && S.map(S.prop('deviceId'), selection),
        rule: S.maybeToNullable(toDSL(criteria)),
      }),
      this.props.stepper.getData(STEP1),
    ));
  }

  render() {
    const { intl, pending, stepper, cancelAction } = this.props;
    const step2 = S.fromMaybe({}, stepper.getData(STEP1));

    return (
      <StepperContent
        loading={pending}
        onSubmit={this.complete}
        title={title}
        actions={[
          cancelAction,
          <Button raised type="submit">
            <FormattedMessage {...messages.complete} />
          </Button>,
        ]}
      >
        <Form>
          <DisplayField label={intl.formatMessage(messages.groupName)}>
            <TextField
              inputRef={(input) => { this.nameInput = input; }}
              name="group-name"
              type="text"
            />
          </DisplayField>
          <DisplayField label={intl.formatMessage(messages.groupType)}>
            <GroupType type={step2.type} />
          </DisplayField>
          {step2.criteria && step2.type === TYPE_DYNAMIC && (
            <DisplayField
              className="display-search-field"
              label={intl.formatMessage(messages.criteria)}
            >
              {<DisplaySearch criteria={step2.criteria} />}
            </DisplayField>
          )}
          {step2.selection && (
            <DisplayField label={intl.formatMessage(messages.devicesSelected)}>
              {step2.selection.length}
            </DisplayField>
          )}
        </Form>
      </StepperContent>
    );
  }
}

Step3.propTypes = {
  intl: intlShape.isRequired,
  connect: func.isRequired,
  stepper: shape().isRequired,
  create: func.isRequired,
  pending: bool.isRequired,
};

export default compose(
  connect(
    state => ({
      pending: selectors.isPending(state),
    }),
    {
      create: actions.create,
    },
  ),
  injectIntl,
)(Step3);
