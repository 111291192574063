import {
  collection,
} from 'common/store/actions';

export const NAMESPACE = 'DEVICE/SAMPLES_HISTORY';

const collectionActions = collection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  CLEAR,
} = collectionActions.TYPES;

export const {
  loadSuccess,
  loadError,
  clear,
} = collectionActions;

export const load = ({ onDemandReading, deviceId, meteringProfile, from, to, ...props }) => ({
  ...collectionActions.load(props),
  deviceId,
  meteringProfile,
  from,
  to,
  onDemandReading,
});
