import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { string } from 'prop-types';
import { Link } from 'found';
import Icon from 'common/mdc/icon';
import Drawer from 'common/mdc/drawer';

const messages = defineMessages({
  dashboard: {
    id: 'drawer.title.dashboard',
    defaultMessage: 'Dashboard',
  },
  devices: {
    id: 'drawer.title.devices',
    defaultMessage: 'Meters',
  },
  groups: {
    id: 'drawer.title.groups',
    defaultMessage: 'Groups',
  },
  meteringProfiles: {
    id: 'drawer.title.meteringProfiles',
    defaultMessage: 'Metering Profiles',
  },
});

const NavItem = ({ to, icon, title }) => (
  <Link to={to} className="mdc-list-item" activeClassName="mdc-list-item--activated">
    <Icon className="mdc-list-item__graphic" aria-hidden="true" title={title}>{icon}</Icon>
    <span className="description">{title}</span>
  </Link>
);

NavItem.propTypes = {
  to: string.isRequired,
  icon: string.isRequired,
  title: string.isRequired,
};

export default injectIntl(({ intl }) => (
  <Drawer>
    { <NavItem exact
      to="/dashboard"
      icon="dashboard"
      title={intl.formatMessage(messages.dashboard)}
    /> }
    <NavItem
      to="/devices"
      icon="flash_on"
      title={intl.formatMessage(messages.devices)}
    />
  </Drawer>
));
